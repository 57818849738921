@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{

  text-decoration: none;
  font-family: 'Open Sans', sans-serif;

}

/* Custom Properties */

:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #dadce1;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.title_1{
  position: relative;
  border-bottom: 1px solid;
  border-bottom-color: rgb(175, 175, 175);
  border-bottom-color: rgba(146, 146, 146, 0.2);
  background-color: rgba(224, 224, 224, 0.664);
  box-shadow: 0 2px 5px #66C8F2;
  height: 195px;
}

h3{
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid;
  border-bottom-color: rgb(175, 175, 175);
  border-bottom-color: rgba(146, 146, 146, 0.2);
  background-color: rgba(224, 224, 224, 0.664);
  box-shadow: 0 2px 5px #66C8F2;
  height: 100px;
}

span{
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid;
  border-bottom-color: rgb(175, 175, 175);
  border-bottom-color: rgba(146, 146, 146, 0.2);
  background-color: rgba(224, 224, 224, 0.664);
  box-shadow: 0 2px 5px #66C8F2;
  height: 700px;

}

h1:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20width%3D%221920%22%20height%3D%221120%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22blur%22%3E%3CfeGaussianBlur%20stdDeviation%3D%225%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3Cimage%20xlink%3Ahref%3D%22http%3A%2F%2Fplacekitten.com%2F1920%2F1120%22%20width%3D%221920%22%20height%3D%221120%22%20filter%3D%22url%28%23blur%29%22%2F%3E%3C%2Fsvg%3E"), url("http://placekitten.com/1920/1120");
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  content: "";
  filter: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22blur%22%3E%3CfeGaussianBlur%20stdDeviation%3D%225%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E#blur");
  -webkit-filter: blur(15px);
  filter: blur(15px);
}

@media only screen {
  span {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.btn{
  touch-callout: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  border: .2em solid;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.6;
  color: #FFF;
}

/* NavBar */


.navbar{
  height: 60px;
  height: var(--nav-size);
  padding: 0 1rem;
  border-bottom: #ECF8FE;
}

.navbar-nav{
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-item{
  width: calc(60px * 0.8);
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #159BD4;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button:hover{
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  text-decoration: none;
}


/* Home  */

.Home-Wrapper{
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  margin: 15px;
  padding: 15px;
}

.Video{
  display: grid;
  grid-column: 2 / span 1;
  grid-row: 6 / 5;
  position: relative;
  padding-top: 5rem;
  justify-items: center;
  align-items: center;
  background-position: center;
  height:100%;
  width:100%;
}

.Before--After{
  display: grid;
  grid-row: 2 / 3;
}

Span{
  display: grid;
  grid-row: 2 / 3;
}

@media screen and (max-width: 768px) {

    .Home-Wrapper {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
        /* background-color: #5295ffe0; */
    }

    .Before--After{
      grid-row: 2 / 2;
      width: 100%;
      height: auto;
    }

    
    Span{
      display: grid;
      grid-row: 3 / 3;
    }
    
    .Video{
      padding-top: 2rem;
      grid-column: 1 / span 1;
      grid-row: 6 / 5;
    }

    .Video Iframe{
      width: 100%;
      height: auto;
    }
}

@media screen and (max-width: 320px) {
    .Home-Wrapper {
      display: grid;
      grid-auto-flow: column;
      justify-items: center;
      align-items: center;
    }

    .Before--After{
      width: 100%;
      height: auto;
    }

        Span{
      display: grid;
      grid-row: 3 / 3;
    }
    
    .Video{
      padding-top: 2rem;
      grid-column: 1 / span 1;
      grid-row: 6 / 5;
    }

    .Video Iframe{
      width: 100%;
      height: auto;
    }
}

/* TablePlan */

.List{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row: 1 / 5;
    position: relative;
    padding-top: 5rem;
    justify-items: center;
    align-items: center;
    background-position: center;
    height:100%;
    width:100%;
}

.list-group{
  background-color: #8BC6EC;
  background: linear-gradient(-135deg, #8BC6EC 0%, #9599E2 100%);
}

.list-group-item{
  background-color: #8BC6EC;
  background: linear-gradient(-150deg, #8BC6EC 0%, #9599E2 100%);
}

@media screen and (max-width: 768px) {


    .List {
        display: grid;
        grid-row: 1 / 1;
        justify-items: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

}

@media screen and (max-width: 320px) {


    .List {
        display: grid;
        justify-items: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.Button{
  --button-size: calc(var(--nav-size) * 0.5);
  width: 225px;
  height: 50px;
  background-color: #159BD4;
  opacity: 0.1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  /* border-radius: 50%; */
  position: -webkit-sticky;
  position: sticky;
  margin: 2px;
  padding: 1rem;
  top: 1px;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.Button:hover {
    opacity: 1;
    text-decoration: none;
    color: #000;
    background-color: #66C8F2;
}




/* footer */

.footer {
  margin-top: 5%;
  width: 100%;
  text-align: center;
  padding: 2.5em 0;
  font-size: 1.25rem;
  font-size: var(--fs-h3);
  height: 12rem;
  align-items: center;
  padding: 0 1.5em;
  text-decoration: none;
}

.footer a {
  color: #ECF8FE;
  text-decoration: none;
}

.footer__link:hover,
.social-list__link:hover {
  opacity: 0.7;
  color: #33B9F2;
}

.footer__link:hover {
  text-decoration: none;
  color: #33B9F2;
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
}

.social-list__item {
  margin: 0 0.5em;
}

.social-list__link {
  padding: 0.5em;
}

marquee{
  font-size: small;
  color: #ECF8FE;
}

.footer--text {
  padding-top: 25px;
}
* {
    font-family: 'Lato';
    margin: 0px;
    text-decoration: none;
}


link{
    color:#000
}

a {
    color: #000;
}
.grid{
    min-height: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-flow: row;
    grid-gap: 100px;
    justify-items: center;
    align-items: center;
    margin: 15px;
    padding: 15px;
    text-decoration: none;
}


.card{
    box-shadow: -4px 8px 12px 0px rgba(179,179,179,1);
    width: 230px;
    border: 1px solid silver;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    transition: transform 0.5s;
    color: #159BD4;
    /* background-color: #ffffff3b; */
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
}

.card:hover{
    box-shadow: 0px 0px 8px #66C8F2;
    transition: .5s;
    transform: translate(10px,-5px);
}

.list-group-flush{
    color: #2380A8;
    font-weight: 400;
    font-size: 15px;
}

.CardHeader{
    text-align: center;
    margin: 30px 0 20px 0;
}

.CardButton{
    background-color: #159BD4;
    border: none;
    padding: 3px 10px;
    border-radius: 20%;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.1;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 2px;
    padding: 5px 1em;
    color: #000;
    font-size: 13px;
    border-radius: 10px;
}

.Buttons{
    color: #000;
    text-decoration: none;
}

.Buttons:link {
    color: #000;
    text-decoration: none;
    }

.CardLink{
    text-decoration: none;
    color: #000;
    cursor:pointer; 
}

.CardButton:hover {
    opacity: 1;
    text-decoration: none;
    color: #000;
    background-color: #66C8F2;
}

@media screen and (max-width: 768px) {


    .card {
        display: grid;
        justify-items: center;
        align-items: center;
    }
}

@media screen and (max-width: 320px) {


    .card {
        display: grid;
        justify-items: center;
        align-items: center;
    }
}



.APP{
  background-image: url("https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80");
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  font-size: 100%;
}


