* {
    font-family: 'Lato';
    margin: 0px;
    text-decoration: none;
}


link{
    color:#000
}

a {
    color: #000;
}
.grid{
    min-height: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-flow: row;
    grid-gap: 100px;
    justify-items: center;
    align-items: center;
    margin: 15px;
    padding: 15px;
    text-decoration: none;
}


.card{
    box-shadow: -4px 8px 12px 0px rgba(179,179,179,1);
    width: 230px;
    border: 1px solid silver;
    height: max-content;
    transition: transform 0.5s;
    color: #159BD4;
    /* background-color: #ffffff3b; */
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
}

.card:hover{
    box-shadow: 0px 0px 8px #66C8F2;
    transition: .5s;
    transform: translate(10px,-5px);
}

.list-group-flush{
    color: #2380A8;
    font-weight: 400;
    font-size: 15px;
}

.CardHeader{
    text-align: center;
    margin: 30px 0 20px 0;
}

.CardButton{
    background-color: #159BD4;
    border: none;
    padding: 3px 10px;
    border-radius: 20%;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 2px;
    padding: 5px 1em;
    color: #000;
    font-size: 13px;
    border-radius: 10px;
}

.Buttons{
    color: #000;
    text-decoration: none;
}

.Buttons:link {
    color: #000;
    text-decoration: none;
    }

.CardLink{
    text-decoration: none;
    color: #000;
    cursor:pointer; 
}

.CardButton:hover {
    opacity: 1;
    text-decoration: none;
    color: #000;
    background-color: #66C8F2;
}

@media screen and (max-width: 768px) {


    .card {
        display: grid;
        justify-items: center;
        align-items: center;
    }
}

@media screen and (max-width: 320px) {


    .card {
        display: grid;
        justify-items: center;
        align-items: center;
    }
}


